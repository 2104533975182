@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  outline: none !important;
  text-decoration: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #636363;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif !important;
}

#root {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: #1d3155;
  width: fit-content;
  display: inline-block;
}

.container-wrapper {
  width: 100%;
}

.container-wrapper-form {
  width: 100%;
  max-width: 768px;
  margin: 0px auto;
  padding-bottom: 24px;
}

.header-section {
  display: flex;
  padding: 24px 16px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.header-section-logo {
  height: 44px;
  margin-right: 24px;
}

.header-subsection {
  display: flex;
  padding: 24px 16px;
  margin-bottom: 24px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.header-subsection-title {
  margin: 0px;
  font-size: 22px;
  line-height: 28px;
}

.form-section {
  padding: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.form-section:last-of-type {
  margin-bottom: 0px;
  border-bottom: 1px solid transparent;
}
.form-section--separator {
  padding-top: 0px;
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  justify-content: center;
}
.form-section-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
.form-section-description {
  font-size: 16;
  font-size: 400;
  margin-bottom: 0px;
}
.form-section-list li {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}
.form-section-list li:last-child {
  margin-bottom: 0px;
}
.form-section-title {
  margin: 0px;
  font-size: 22px;
  line-height: 28px;
}
.form-section-link {
  font-size: 14px;
  line-height: 18px;
}

.content-section {
  padding: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.content-section:last-of-type {
  margin-bottom: 0px;
  border-bottom: 1px solid transparent;
}
.content-section-wrapper {
  margin-bottom: 32px;
}
.content-section-wrapper:last-of-type {
  margin-bottom: 0px;
}
.content-section-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
.content-section-description {
  font-size: 16;
  font-size: 400;
  margin-bottom: 0px;
}
.screenshot-image {
  max-height: 400px;
}

.input-wrapper {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-inliner {
  width: 100%;
}
.input-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.input-field {
  width: 100%;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.085);
}
.input-field:hover,
.input-field:focus,
.input-field:active {
  outline: none;
  box-shadow: none;
  border: 1px solid #19b80f;
}
.input-select {
  height: 42px;
  max-width: 100px;
  padding: 8px 16px;
  margin-right: 8px;
}
.input-select-fw {
  height: 42px;
  padding: 8px 16px;
  margin-right: 8px;
}
.input-field-dp {
  width: 100% !important;
}
.input-message {
  font-size: 12px;
  margin-top: 4px;
  color: #ff0000;
}
.input-wrapper .input-message {
  display: none;
}
.input-checkbox {
  margin-right: 4px;
}
.input-map {
  margin-bottom: 16px;
}

.input-error .input-field {
  border: 1px solid #ff0000;
  background-color: rgb(145, 18, 35, 0.065);
}
.input-error .input-message {
  display: block;
}
.input-label a {
  margin: 0px 4px;
  font-weight: 500;
  color: #1d3155;
}
.input-label a:hover,
.input-label a:focus,
.input-label a:active,
.input-label a:visited {
  text-decoration: none;
}
.input-label ul {
  margin: 0px;
  padding: 0px;
  padding-left: 16px;
}

.input-assist {
  margin: 0px;
  display: block;
  font-size: 400;
  font-size: 14px;
  line-height: 20px;
}

.button {
  outline: none;
  min-width: 175px;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid transparent;
}
.button-primary {
  color: #fff;
  font-weight: 700;
  margin-right: 8px;
  background: linear-gradient(148deg, #58f2e9 -48px, #0390f2);
}
.button-primary:hover,
.button-primary:focus,
.button-primary:active {
  outline: none;
  color: #fff;
  box-shadow: none;
}

.button-secondary {
  color: rgb(145, 18, 35);
  background-color: transparent;
}
.button-secondary:hover,
.button-secondary:focus,
.button-secondary:active {
  outline: none;
  box-shadow: none;
}

.validation-errors-item {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  color: #e44242;
  border: 1px solid #e44242;
}
.validation-errors-item:last-of-type {
  margin-bottom: 32px;
}

.page-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.85);
}
.page-loader-asset {
  margin-bottom: 8px;
}
.page-loader-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: rgb(145, 18, 35);
}

.responder-details {
  width: 100%;
  min-height: 100%;
  padding-bottom: 24px;
  background-color: #f8f8f8;
}
.responder-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.responder-image {
  width: 380px;
  height: 380px;
  margin-bottom: 24px;
}

.status-wrapper {
  padding: 16px;
}

.status-wrapper-button {
  margin: 0px 10px 0px 10px;
}

.status-text {
  padding-left: 16px;
  color: rgb(145, 18, 35);
}

.footer-section {
  padding: 3em 16px;
}
.footer-section-logo {
  height: 44px;
}

/* Add Responsive */
@media only screen and (max-width: 767px) {
  .container-wrapper-form {
    max-width: initial;
    margin: 0px;
  }

  .header-section {
    justify-content: space-between;
  }

  .header-section-logo {
    margin-right: 0px;
  }
}
